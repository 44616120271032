var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"ayu-button",class:[
    ("ayu-button-" + _vm.size), 
    ("ayu-button-" + _vm.type), 
    {
      'is-round': _vm.round, 
      'is-disabled': _vm.disabled,
      'is-circle': _vm.circle,
      'is-outline': _vm.outline,
    }
  ],on:{"click":function (event) { return !_vm.disabled && _vm.$emit('click', event); }}},[(_vm.loading)?_c('ayu-icon',{attrs:{"iconClass":"loading","spin":""}}):_vm._e(),(_vm.icon && !_vm.loading)?_c('ayu-icon',{attrs:{"iconClass":_vm.icon}}):_vm._e(),(_vm.$slots.default)?_c('span',[_vm._t("default")],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }